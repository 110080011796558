// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import Navbar from "./components/Navbar";
import MainPage from "./components/MainPage";
import About from "./components/About";
import Books from "./components/Books";
import Contact from "./components/Contact";
import Shop from "./components/Shop";
import NewsletterSignup from "./components/NewsletterSignup";
import Reviews from "./components/Reviews";
import ArtSection from "./components/Art";
import Readings from "./components/Readings";
import Services from "./components/Services";

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/books" element={<Books />} />
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/art" element={<ArtSection />} />
          <Route path="/readings" element={<Readings />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/newsletter" element={<NewsletterSignup />} />
          <Route path="/services" element={<Services/>} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
};

export default App;
