import React from "react";
import { Box, Container, Heading, Text, Image, Center } from "@chakra-ui/react";

const About = () => {
  return (
    <Box
      py={10}
      bg="transparent"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Center mb={8}>
        <Heading as="h1" size="xl" color="white">
          About the Author
        </Heading>
      </Center>
      <Container
        maxW="container.sm"
        mb={4}
        p={2}
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        bg="white"
        color="black"
        alignItems="center"
        justifyContent="center"
      >
        <Center mb={8}>
          <Box display="flex" alignItems="center" mt={4}>
            <Image
              src="./145.jpeg" // Replace with actual image URL
              alt="Author"
              borderRadius="full"
              boxSize="150px"
              objectFit="cover"
              mr={4}
            />
            <Box>
              <Heading as="h2" size="lg" color="black">
                Liana Valerian
              </Heading>
            </Box>
          </Box>
        </Center>
        <Center mb={4}>
          <Text
            color="black"
            textAlign="center"
            fontWeight="bold"
            fontSize="lg"
          >
            Liana Valerian is a passionate gamer, obsessed bookworm,
            enthusiastic sci-fi and fantasy nerd, and self-proclaimed crazy cat
            lady.
            <br />
            She has been crafting tales since she can remember and has finally
            taken the plunge to share them with the world.
            <br />
            You can follow her adventures and connect with her on social media.
            <br/>
            MA in English Teaching & BA in English Language and Literature.
            <br/>
            3,000+ books read and a lifelong love of stories.
            <br/>
            Dedicated to helping authors bring their vision to life.
            <br/>
            <br/>
            <i>"The grammar fiend every author needs in their corner."</i> 
            <br/>Marianne Grey - "Of Shadow and Argent"
          </Text>
        </Center>
      </Container>
    </Box>
  );
};

export default About;
