import React from "react";
import {
  Box,
  Grid,
  GridItem,
  Text,
  Heading,
  Button,
  Container,
} from "@chakra-ui/react";

const servicesData = [
  {
    id: 1,
    title: "Proofreading",
    price: "$0.003 per word",
    description: (
      <>
        <Text>✔ The final polish for a flawless manuscript:</Text>
        <Text>✔ Grammar, punctuation, and spelling corrections</Text>
        <Text>✔ Consistent formatting for a professional look</Text>
      </>
    ),
    serviceEmailSubject: "Inquiry about Proofreading Service",
  },
  {
    id: 2,
    title: "Line Editing",
    price: "$0.006 per word",
    description: (
      <>
        <Text>✔ Enhance your prose and elevate your story:</Text>
        <Text>✔ Sentence restructuring for clarity and flow</Text>
        <Text>✔ Improved word choice and tone consistency</Text>
        <Text>✔ Detailed feedback to refine your manuscript</Text>
      </>
    ),
    serviceEmailSubject: "Inquiry about Line Editing Service",
  },
  {
    id: 3,
    title: "Copy Editing",
    price: "$0.007 per word",
    description: (
      <>
        <Text>✔ A thorough technical and stylistic edit to make your manuscript shine:</Text>
        <Text>✔ Grammar, punctuation, and spelling corrections</Text>
        <Text>✔ Refinement of sentence structure and word choice</Text>
        <Text>✔ Ensures consistency in style, tone, and formatting</Text>
        <Text>✔ Fact-checking for accuracy (names, dates, timelines)</Text>
        <Text>✔ Checks for minor plot or character inconsistencies</Text>
      </>
    ),
    serviceEmailSubject: "Inquiry about Copy Editing Service",
  },
  {
    id: 4,
    title: "Custom Service",
    price: "Varies",
    description: (
      <>
        <Text>✔ Create your own service package:</Text>
        <Text>✔ Mix and match any of the services you need</Text>
        <Text>✔ Let me know what you're looking for, and we’ll tailor a plan just for you!</Text>
      </>
    ),
    serviceEmailSubject: "Inquiry about Custom Service",
  },
];

const Services = () => {
  return (
    <Box p={4}>
      <Heading as="h1" size="xl" mb={8} textAlign="center">
        Services
      </Heading>
      
      {/* Single Book my services Button */}
      <Button
        as="a"
        href="mailto:liana.valerian@gmail.com?subject=Inquiry about Services&body=Hello, I am interested in your services. Please provide me with more details."
        bgColor="#d791a6"
        size="lg"
        mb={8}    
        width= 'fit-content'
        align-items= 'center'
        justify-content= 'center'
        display= 'flex'
        mx="auto"
        _hover={{
          bg: "#101925",
          color: "#d791a6",
        }}
      >
        Book my services!
      </Button>

      {/* Service grid */}
      <Container maxW="container.md">
        <Grid
          templateColumns={{
            base: "1fr", // Single column for small screens
            md: "1fr 1fr", // Two columns for medium and larger screens
          }}
          gap={6}
        >
          {servicesData.map((service) => (
            <GridItem
              key={service.id}
              p={4}
              borderWidth="1px"
              borderRadius="lg"
              bg="white"
              color="black"
              shadow="md"
              _hover={{ shadow: "lg", transform: "scale(1.02)" }}
              transition="all 0.2s"
            >
              <Heading as="h2" size="md" mb={2}>
                {service.title}
              </Heading>
              <Text fontSize="lg" fontWeight="bold" mb={4} color="#d791a6">
                {service.price}
              </Text>
              <Box fontSize="md" color="gray.700">
                {service.description}
              </Box>
            </GridItem>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Services;
